import React from 'react';
import { Bs2Logo } from './Bs2Logo';
import { BtgLogo } from './BtgLogo';
import { Recipient } from './Recipient';
import { CurrencyText } from '../../components/CurrencyText';
import { Payer } from './Payer';
import { BarCode } from './BarCode';
import { separatorColor, styles } from './pdfStyles';
import { Text, View } from '@react-pdf/renderer';
import { DateText } from '../../components';
import { BANKS } from '../../const';
import { getBankFromCode } from '../../utils';

const receiptStyles = {
  wrapper: {
    display: 'block'
  },
  header: {
    borderLeftColor: separatorColor,
    borderLeftWidth: 1,
    flexDirection: 'row',
    margin: '10mm 0',
    width: '100%',
    minHeight: 80 * 0.56
  },
  headerCol: {
    borderRightColor: separatorColor,
    borderRightWidth: 1,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5mm'
  },
  headerLogoCol: {
    maxWidth: '20%'
  },
  receiptTitle: {
    fontSize: 18,
    fontWeight: 400
  }
};

export const Receipt = ({ dataBilling }) => {
  return (
    <View style={receiptStyles.wrapper} wrap={false}>
      <View style={receiptStyles.header}>
        <View style={[receiptStyles.headerCol, receiptStyles.headerLogoCol]}>
        {getBankFromCode(dataBilling.invoiceBarCode) === BANKS.BTG ? <BtgLogo /> : <Bs2Logo />}
        </View>
        <View style={receiptStyles.headerCol}>
          <Text style={receiptStyles.receiptTitle}>Boleto Bancário</Text>
        </View>
        <View style={receiptStyles.headerCol}>
          <Text style={styles.label}>Data de Vencimento</Text>
          <Text style={styles.text}>
            <DateText date={dataBilling.invoiceDueDate} />
          </Text>
        </View>
        <View style={receiptStyles.headerCol}>
          <Text style={styles.label}>Valor Total</Text>
          <Text style={styles.text}>
            <CurrencyText value={dataBilling.invoiceAmount} />
          </Text>
        </View>
      </View>
      <Recipient dataBilling={dataBilling} />
      <Payer dataBilling={dataBilling} />
      {dataBilling.invoiceStatus === 'Pending' && (
        <BarCode code={dataBilling.invoiceBarCode} />
      )}
    </View>
  );
};
