import React from 'react';
import { Svg, Path, G } from '@react-pdf/renderer';

export const BtgLogo = ({ width = 80 }) => {
  const height = width * 0.43;
  return (
    <Svg style={{ width, height }} viewBox={`0 0 0 0`}>
      <G transform="translate(0.000000,32.800000) scale(0.010000,-0.010000)" fill="#000000" stroke="none">
        <Path d="M1310 3250 c-295 -41 -571 -166 -773 -349 -246 -223 -386 -465 -458
        -792 -33 -146 -33 -406 -1 -556 43 -201 128 -396 242 -556 79 -109 249 -274
        360 -348 369 -246 827 -309 1247 -173 117 38 287 123 385 194 93 66 240 206
        290 276 l29 41 162 6 c89 3 185 10 213 17 69 15 150 66 185 115 53 75 61 133
        57 458 l-3 292 -33 67 c-36 73 -75 109 -156 142 -44 18 -72 21 -206 21 -137 0
        -161 -3 -211 -23 -148 -59 -216 -221 -190 -455 25 -220 143 -314 384 -305 86
        3 111 8 156 30 l53 26 -4 -64 c-4 -71 -24 -107 -78 -138 -24 -15 -60 -20 -152
        -25 -137 -7 -121 4 -232 -150 -270 -373 -751 -584 -1231 -539 -605 56 -1114
        528 -1222 1132 -22 124 -21 351 2 481 83 474 438 892 895 1053 340 120 755 95
        1070 -63 245 -123 479 -347 596 -570 34 -65 42 -75 67 -75 15 0 27 2 27 5 0
        31 -133 237 -217 336 -197 234 -502 409 -825 474 -90 19 -340 27 -428 15z
        m1660 -1322 c60 -31 72 -69 74 -223 1 -104 -2 -125 -19 -155 -31 -52 -74 -70
        -175 -70 -101 0 -144 18 -175 70 -17 30 -20 52 -20 165 0 149 13 186 75 217
        54 27 183 25 240 -4z"/>
        <Path d="M958 2417 c-38 -6 -68 -15 -69 -21 -5 -55 -2 -768 3 -822 22 -209
        146 -304 399 -304 225 0 347 73 394 234 22 76 17 338 -7 404 -52 140 -157 202
        -344 202 -84 0 -151 -13 -226 -45 -17 -7 -18 6 -18 179 l0 186 -32 -2 c-18 0
        -63 -6 -100 -11z m452 -489 c71 -37 85 -75 85 -238 0 -121 -3 -140 -23 -177
        -30 -57 -69 -76 -167 -80 -69 -4 -86 -1 -125 19 -73 37 -85 73 -85 248 0 170
        10 200 78 231 62 28 179 26 237 -3z"/>
        <Path d="M1975 2309 c-27 -4 -65 -11 -83 -14 l-33 -6 3 -402 c5 -455 7 -469
        79 -530 55 -48 97 -59 246 -64 l132 -5 6 28 c4 16 11 45 16 64 18 71 19 70
        -81 70 -179 0 -190 17 -190 284 l0 185 132 3 131 3 14 60 c7 33 15 70 18 83
        l5 22 -150 0 -150 0 0 115 c0 131 8 121 -95 104z"/>
        <Path d="M7350 1830 l0 -480 30 0 30 0 0 480 0 480 -30 0 -30 0 0 -480z"/>
        <Path d="M5527 2213 c-3 -5 -4 -165 -3 -358 l1 -350 25 -50 c38 -77 86 -99
        215 -100 l100 0 3 26 3 27 -99 4 c-176 7 -182 18 -182 338 l0 230 140 0 c105
        0 140 3 140 13 0 6 3 22 6 35 l6 22 -146 0 -146 0 0 85 0 85 -29 0 c-17 0 -32
        -3 -34 -7z"/>
        <Path d="M3757 2050 c-60 -11 -129 -51 -158 -91 -12 -17 -30 -58 -40 -91 -17
        -53 -19 -96 -19 -394 l0 -334 30 0 30 0 0 150 0 150 42 -30 c81 -59 217 -77
        329 -44 73 22 131 80 153 153 22 74 22 287 0 360 -20 69 -80 134 -142 155 -58
        20 -162 28 -225 16z m223 -83 c35 -17 51 -34 70 -72 23 -46 25 -60 25 -195 0
        -127 -3 -151 -21 -191 -33 -71 -77 -93 -194 -97 -88 -3 -99 -1 -150 25 -81 41
        -102 86 -108 223 -9 213 37 302 170 330 62 13 156 3 208 -23z"/>
        <Path d="M4327 2036 c-4 -10 -7 -26 -7 -37 0 -18 9 -19 148 -19 180 0 221 -14
        252 -82 17 -37 29 -158 16 -158 -2 0 -25 10 -52 22 -59 25 -216 36 -280 19
        -93 -25 -139 -82 -150 -187 -12 -113 34 -198 123 -228 53 -18 217 -22 274 -6
        57 16 102 51 127 100 22 43 23 53 20 240 -3 163 -6 200 -21 227 -46 86 -110
        111 -308 120 -123 5 -136 4 -142 -11z m331 -323 c61 -30 77 -63 77 -153 0 -69
        -3 -81 -24 -104 -36 -38 -84 -49 -204 -44 -119 4 -148 17 -179 79 -35 68 -10
        189 46 218 64 35 218 37 284 4z"/>
        <Path d="M5169 2040 c-118 -14 -194 -73 -224 -172 -20 -68 -20 -267 -1 -332
        24 -78 58 -121 123 -152 52 -26 69 -29 186 -32 135 -5 146 -2 147 41 0 15 -12
        17 -111 17 -141 0 -192 13 -239 60 -51 51 -63 102 -58 252 4 144 19 185 81
        226 38 25 49 27 178 30 131 3 137 4 143 25 12 46 10 47 -76 46 -46 -1 -113 -5
        -149 -9z"/>
        <Path d="M5994 2035 c-11 -28 3 -508 16 -550 17 -54 75 -110 130 -126 57 -16
        192 -16 247 -1 53 16 98 53 126 106 21 39 22 55 25 314 l3 272 -30 0 -31 0 0
        -247 c-1 -337 -13 -370 -144 -394 -76 -14 -167 -3 -208 25 -67 44 -73 71 -76
        359 l-4 257 -24 0 c-14 0 -27 -7 -30 -15z"/>
        <Path d="M6737 2036 c-4 -10 -7 -26 -7 -37 0 -18 9 -19 148 -19 180 0 221 -14
        252 -82 17 -37 29 -158 16 -158 -2 0 -25 10 -52 22 -42 20 -66 23 -169 23
        -110 0 -124 -2 -165 -25 -79 -44 -116 -152 -90 -261 26 -108 98 -149 259 -149
        151 0 217 28 259 110 22 43 23 53 20 240 -3 163 -6 200 -21 227 -46 86 -110
        111 -308 120 -123 5 -136 4 -142 -11z m331 -323 c61 -30 77 -63 77 -153 0 -69
        -3 -81 -24 -104 -36 -38 -84 -49 -204 -44 -119 4 -148 17 -179 79 -35 68 -10
        189 46 218 64 35 218 37 284 4z"/>
        <Path d="M3603 820 c-59 -35 -68 -61 -68 -190 0 -181 28 -210 204 -210 83 0
        101 3 101 15 0 12 -16 15 -85 15 -92 0 -147 16 -163 47 -6 10 -13 39 -17 66
        l-7 47 138 0 c77 0 145 4 153 9 10 6 12 27 9 79 -4 78 -20 105 -75 128 -49 21
        -151 18 -190 -6z m203 -44 c18 -21 24 -39 24 -77 l0 -49 -131 0 -132 0 7 47
        c12 90 44 114 145 110 55 -3 67 -7 87 -31z"/>
        <Path d="M4042 825 c-53 -23 -62 -60 -62 -245 0 -152 1 -160 19 -160 19 0 20
        10 23 170 3 161 4 171 26 192 18 19 33 23 83 23 54 0 63 -3 85 -28 23 -27 24
        -33 24 -193 0 -155 1 -164 19 -164 18 0 20 10 23 170 3 157 5 171 24 190 44
        45 166 33 185 -17 5 -13 9 -96 9 -184 0 -152 1 -159 20 -159 19 0 20 7 20 175
        l0 175 -29 32 c-27 30 -34 33 -102 36 -67 3 -78 1 -113 -23 l-39 -26 -24 23
        c-31 29 -140 37 -191 13z"/>
        <Path d="M4739 820 c-62 -33 -69 -66 -69 -325 0 -218 1 -225 20 -225 19 0 20
        7 20 90 0 50 2 90 4 90 3 0 21 -9 41 -21 30 -18 48 -21 115 -18 71 4 82 7 109
        33 38 36 55 111 49 216 -8 136 -44 174 -171 178 -66 2 -89 -2 -118 -18z m180
        -21 c51 -18 66 -57 66 -179 -1 -67 -5 -114 -14 -128 -26 -48 -126 -67 -196
        -38 -55 23 -67 60 -63 186 3 98 5 111 27 134 13 14 33 27 45 29 43 9 104 7
        135 -4z"/>
        <Path d="M5510 829 c-70 -29 -96 -99 -87 -237 8 -139 49 -172 210 -172 80 0
        97 3 97 15 0 12 -16 15 -82 16 -138 1 -167 19 -182 117 l-6 42 137 0 c76 0
        144 4 152 9 9 6 13 27 12 64 -1 68 -11 95 -46 127 -23 21 -37 25 -103 27 -42
        2 -88 -2 -102 -8z m154 -29 c34 -13 56 -49 62 -104 l6 -47 -133 3 -134 3 3 40
        c5 53 22 87 52 102 30 15 108 17 144 3z"/>
        <Path d="M5195 816 c-50 -22 -58 -53 -63 -233 l-4 -163 26 0 26 0 0 164 c0
        204 2 208 108 214 48 3 72 9 76 18 7 19 -126 19 -169 0z"/>
        <Path d="M5913 815 c-40 -17 -53 -42 -53 -98 0 -60 36 -92 112 -101 152 -19
        163 -24 163 -85 0 -67 -16 -75 -155 -79 -98 -3 -120 -6 -120 -19 0 -12 20 -14
        131 -11 l131 3 29 33 c48 53 35 142 -23 170 -15 7 -64 17 -109 22 -52 6 -89
        16 -100 26 -31 28 -23 88 14 115 1 1 53 4 116 8 78 4 116 10 119 19 6 18 -212
        16 -255 -3z"/>
        <Path d="M6310 816 c0 -13 13 -16 78 -17 133 -1 172 -27 172 -117 l0 -42 -25
        16 c-32 21 -129 29 -182 15 -73 -20 -108 -95 -81 -176 21 -63 56 -76 203 -73
        l120 3 3 155 c4 228 -6 242 -180 248 -94 4 -108 2 -108 -12z m210 -191 c33
        -17 43 -52 38 -125 l-3 -45 -102 1 c-127 0 -143 10 -143 88 0 56 5 67 35 84
        29 17 141 15 175 -3z"/>
        <Path d="M6754 814 c-48 -24 -66 -80 -44 -138 14 -39 48 -54 145 -66 55 -7 97
        -17 107 -27 31 -27 22 -96 -15 -124 -1 -1 -56 -4 -121 -8 -82 -4 -121 -10
        -124 -18 -3 -10 25 -13 118 -13 169 0 200 18 200 118 0 44 -4 54 -31 76 -17
        14 -36 26 -42 26 -54 2 -165 23 -184 35 -30 20 -32 74 -2 101 17 16 40 20 135
        23 89 2 114 6 114 17 0 21 -213 19 -256 -2z"/>
      </G>
    </Svg>
  );
};
